import classes from './AnnotationJobsTable.module.scss'
import {useQuery} from "react-query";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import React, {useState} from "react";
import {AnnotationJobResponse, AppMode} from "../../../../../../AUTO_GENERATED_TYPES";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import {toast} from "react-toastify";
import {getAnnotationJobs, updateBatchArchive, updateBatchDelete} from "../../../../../../_metronic/helpers/backend_helper";
import usePreloader from "../../../../../../store/layout/usePreloader";
import {Col, Row} from "react-bootstrap";
import tableClasses from "../../../../Community/pages/table.module.scss";
import ArchiveSwitch from "../../../../Community/Components/ArchiveSwitch/ArchiveSwitch";
import JsonViewer from "../JsonViewer";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {UncontrolledTooltip} from "reactstrap";
import {useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import { Pagination } from '@mui/material';
import queryClient from '../../../../../../react-query/queryClient';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from "@mui/material";
import {Button} from "react-bootstrap";


const dateFormatter = (cell: any, row: any) => {
    let date = row.creation_time
        .replace(/[-]/g, '/')
        .replace(/[T]/g, '\u00A0');
    let jsDate = date.slice(0, 16);
    if (row.creation_time) {
        return (
            <span>
                    <p style={{marginBottom: 0, marginLeft: 5}}>
                      {jsDate}
                    </p>
                </span>
        );
    }
}


const AnnotationJobsTable = () => {
    const [page, setPage] = useState<number>(0);
    const [sizePerPage, setSizePerPage] = useState(25)
    const [appMode, setAppMode] = useState<AppMode>(AppMode.LIVE)
    const [showArchive, setShowArchive] = useState(false)
    const [jsonContext, setJsonContext] = useState({show: false, json: {}})
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchField, setSearchField] = useState<string>("");
    const [selectedJobs, setSelectedJobs] = useState<AnnotationJobResponse[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()

    const offset = (page) * sizePerPage;

    const { data, isFetching } = useQuery(
        [QueryKeys.Annotation, QueryKeys.AnnotationJobs, appMode, showArchive, page, sizePerPage, offset, searchTerm],
        () => getAnnotationJobs(appMode, showArchive, sizePerPage, offset, searchTerm, searchField),
        { onError: (err) => toast.error((err as Error)?.message), refetchOnWindowFocus: false }
    );

    usePreloader(isFetching);

    const currentPageData = data?.jobs;
    const totalInstances = data?.total_count || 0;

    const handleSearch = () => {
        setPage(0);
        setSearchTerm(searchInput);
        if (!searchField) {
            setSearchField('all');
        }
    };

    
    const dataFormatter = (data: any, row: any, i: number, extraData: string) => {
        let renderData = data;
    
        if (data == null) {
            renderData = 'N/A';
        } else if (Array.isArray(data)) {
            renderData = data.length > 0 ? data.join(',') : 'N/A';
        } else if (typeof renderData !== 'string' && typeof renderData !== 'number') {
            const onClick = (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setJsonContext({ show: true, json: data });
            };
    

            return <>
                <div id={`json-${i}`} className={'seg_long_copy'} style={{cursor: 'pointer'}}
                     onClick={onClick}>{<KTSVG path={'/media/icons/duotune/general/gen053.svg'}
                                               className='svg-icon-1'/>}</div>
                <UncontrolledTooltip target={`json-${i}`} placement={'bottom'}>Click to show
                    JSON</UncontrolledTooltip>
            </>
        }

        return <>
            <div id={`${extraData}-${i}`}
                 className={'seg_long_copy'}>{renderData ?? 'N/A'}</div>
            <UncontrolledTooltip target={`${extraData}-${i}`}
                                 placement={'bottom'}>{renderData}</UncontrolledTooltip>
        </>
    }

    const columns = [
        {
            dataField: 'id', // number
            // hidden: true,
            text: 'Id',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'creation_time', // string
            text: 'Date',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dateFormatter,
        },
        {
            dataField: 'inference_type', // string
            text: 'Interface Type',
            editable: false,
            sort: true,
            style: {
                // textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'app_names', // Array<string>
            text: 'App names',
            editable: false,
            sort: true,
            style: {
                // textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dataFormatter,
            formatExtraData: 'app_names'
        },
        {
            dataField: 'app_token', // string
            text: 'App Token',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'actual_app_tokens', // Array<string>
            text: 'Actual App Tokens',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dataFormatter,
            formatExtraData: 'actual_app_tokens'

        },
        {
            dataField: 'platforms', // Array<number>
            text: 'Platforms',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dataFormatter,
            formatExtraData: 'platforms'

        },
        {
            dataField: 'researcher', // string
            text: 'Researcher',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'run_name', // string
            text: 'Job Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: ((data: string, row: any, i: number,) => {
                return <>
                    <div id={`run_name-${i}`}
                         className={'seg_long_copy'}>{data ?? 'N/A'}</div>
                    <UncontrolledTooltip target={`run_name-${i}`}
                                         placement={'bottom'}>{data}</UncontrolledTooltip>
                </>
            }),
        },
        {
            dataField: 'job_url', // string
            text: 'Job URL',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => {
                if (cell) {
                    return <a className={'link-primary'} href={cell} target={"_blank"}
                              onClick={e => e.stopPropagation()} rel="noreferrer">{cell?.split('/').at(-1)}</a>
                }
                return 'N/A'
            }
        },
        {
            dataField: 'output_table', // string
            text: 'Output Table',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'cnt_segments', // number
            text: '#Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'cnt_images', // number
            text: '#Images',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'per_images_tagged', // number
            text: 'Images Tagged %',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'job_params', // JSON
            text: 'Job Params',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dataFormatter,
            formatExtraData: 'job_params'

        },

    ];


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selectedJobs.map((job) => job.id),
        onSelect: (row: AnnotationJobResponse, isSelected: boolean) => {
            setSelectedJobs((prev) => {
                if (isSelected) {
                    return [...prev, row];
                } else {
                    return prev.filter((job) => job.id !== row.id);
                }
            });
        },
        onSelectAll: (isSelected: boolean, rows: AnnotationJobResponse[]) => {
            if (isSelected) {
                setSelectedJobs(rows);
            } else {
                setSelectedJobs([]);
            }
        },
    };


    const rowEvents = {
        onClick: (e: any, row: AnnotationJobResponse, rowIndex: number) => {
            navigate(`/annotation/${row.id}`)
        },
    }


const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
};

const handlePageChangeNum = (event: unknown, newPage: number) => {
    setPage(newPage-1);
};

const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSizePerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const totalPages = Math.ceil(totalInstances / sizePerPage);

const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchField(e.target.value);
  };


  const handleBatchArchive = (jobsToArchive: AnnotationJobResponse[]) => {
    if (jobsToArchive.length === 0) return;

    const jobIds = jobsToArchive.map((job) => job.id);

    updateBatchArchive({ job_ids: jobIds, archive: true })
        .then(() => {
            toast.success(`${jobsToArchive.length} job(s) archived successfully!`);
            setSelectedJobs([]);
            queryClient.invalidateQueries([QueryKeys.Annotation, QueryKeys.AnnotationJobs]);
        })
        .catch((err) => {
            toast.error(`Failed to archive jobs: ${(err as Error).message}`);
        });
};

    const handleBatchDelete = (jobsToDelete: AnnotationJobResponse[]) => {
        if (jobsToDelete.length === 0) return;
        setIsLoading(true);
        const jobIds = jobsToDelete.map((job) => job.id);

        updateBatchDelete({ job_ids: jobIds })
        .then(() => {
            toast.success(`${jobsToDelete.length} job(s) deleted successfully!`);
            setSelectedJobs([]);
            queryClient.invalidateQueries([QueryKeys.Annotation, QueryKeys.AnnotationJobs]);
        })
        .catch((err) => {
            toast.error(`Failed to delete jobs: ${(err as Error).message}`);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
    const handleConfirmDelete = () => {
        handleBatchDelete(selectedJobs);
        handleCloseDeleteDialog();
    };

    const handleOpenArchiveDialog = () => setOpenArchiveDialog(true);
    const handleCloseArchiveDialog = () => setOpenArchiveDialog(false);
    const handleConfirmArchive = () => {
        handleBatchArchive(selectedJobs);
        handleCloseArchiveDialog();
    }; 


return (
    <div className={'comm_table'}>
    <div className={classes.container}>
        <JsonViewer isOpen={jsonContext.show} toggle={() => setJsonContext({ show: false, json: {} })} json={jsonContext.json} />


        <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="dialog"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the selected jobs?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                <Button onClick={handleConfirmDelete} className="button confirm-danger" variant='danger'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openArchiveDialog}
            onClose={handleCloseArchiveDialog}
            aria-labelledby="archive-dialog-title"
            aria-describedby="archive-dialog-description"
            className="dialog"
        >
            <DialogTitle id="archive-dialog-title">{"Confirm Archive"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="archive-dialog-description">
                    Are you sure you want to archive the selected jobs?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseArchiveDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmArchive} className="button confirm-danger" variant='danger'>
                    Archive
                </Button>
            </DialogActions>
        </Dialog>

        <ToolkitProvider keyField="id" data={currentPageData ?? []} columns={columns} search bootstrap4>
            {(props: any) => (
                <div style={{ padding: '15px' }}>
                    <div>
                        <br />
                        <div className={'toolbar_table'}>
                            <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <select
                                    value={searchField}
                                    onChange={handleFieldChange}
                                    className="ml-2"
                                >
                                    <option value="all">All Fields</option>
                                    <option value="app_name">App Name</option>
                                    <option value="app_token">App Token</option>
                                    <option value="company_name">Company Name</option>
                                    <option value="platforms">Platforms</option>
                                    <option value="job_params_user">Researcher</option>
                                    <option value="job_params_job_name">Job Name</option>
                                    <option value="user_id">User ID</option>
                                    <option value="device_id">Device ID</option>
                                    <option value="job_id">Job ID</option>
                                    <option value="creation_time">Creation Time</option>
                                </select>
                                <button className="btn btn-primary ml-2" onClick={handleSearch}>
                                    Search
                                </button>
                            </div>
                            </div>
                            <div className={'filter_toolbar'}>
                                <select
                                    value={appMode}
                                    onChange={(e) => {
                                        setAppMode(e.target.value as AppMode);
                                        setPage(0);
                                    }}
                                >
                                    <option value={AppMode.ALL}>- App Mode -</option>
                                    <option value={AppMode.LIVE}>Live</option>
                                    <option value={AppMode.DEMO}>Demo</option>
                                    <option value={AppMode.TESTING}>Testing</option>
                                </select>

                                <ArchiveSwitch
                                    checked={showArchive}
                                    onChange={() => {
                                        setShowArchive((prevState) => !prevState);
                                        setPage(0);
                                    }}
                                />
                                <div className={'toolbar_table'}>
                                    <Button
                                        disabled={selectedJobs.length === 0}
                                        onClick={handleOpenDeleteDialog}
                                        variant={"danger"}
                                        className="button danger">
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-del'
                                        /> 
                                        Delete Selected
                                    </Button>
                                    <button
                                        className="btn btn-primary ml-2"
                                        disabled={selectedJobs.length === 0}
                                        onClick={handleOpenArchiveDialog}
                                    >
                                        Archive Selected
                                    </button>

                            </div>
                            </div>
                        </div>

                        <br />
                    </div>
                    <Row>
                        <Col
                            xl="12"
                            className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}
                        >
                            {isFetching ? (
                                <div>Loading...</div>
                            ) :
                            isLoading ? (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%", 
                                    width: "100%",
                                    minHeight: "200px"
                                }}>
                                    <CircularProgress size={30} color="inherit" />
                                </div>
                            ) : (
                                <>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        bordered={true}
                                        responsive
                                        stripped
                                        headerWrapperClasses={'header-class'}
                                        rowEvents={rowEvents}
                                        rowStyle={{ cursor: 'pointer' }}
                                        selectRow={selectRow}
                                    />
                                   <div className={classes.paginationContainer}>
                                        <TablePagination
                                            component="div"
                                            count={totalInstances}
                                            page={page}
                                            onPageChange={handlePageChange}
                                            rowsPerPage={sizePerPage}
                                            onRowsPerPageChange={handleSizeChange}
                                            rowsPerPageOptions={[25, 50, 100]}
                                            classes={{
                                                root: classes.tablePagination,
                                                toolbar: classes.toolbar,
                                                selectLabel: classes.selectLabel,
                                                select: classes.select,
                                                input: classes.input,
                                                actions: classes.actions,
                                                displayedRows: classes.displayedRows
                                              }}
                                        />
                                        <Pagination
                                            count={totalPages}
                                            page={page + 1}
                                            onChange={handlePageChangeNum}
                                            color="primary"
                                            className={classes.muiPagination}
                                        />
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </ToolkitProvider>
    </div>
    </div>
);
};

export default AnnotationJobsTable;

