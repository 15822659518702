/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import {
    addAnnotationFilter,
    addAnnotationOrder,
    changeAnnotationFilterOperator,
    changeAnnotationFilterSA,
    changeAnnotationFilterType,
    changeAnnotationFilterValue,
    changeAnnotationImageType,
    changeAnnotationOrderType,
    changeAnnotationOrderValue,
    removeAnnotationFilter,
    removeAnnotationOrder,
    resetAnnotationFilter,
    resetAnnotationOrderBy,
    setAnnotationDataRequest,
    setAnnotationFilters,
    setAnnotationIPP,
    setAnnotationPage,
    setAnnotationTagFilter
} from "./actions";
import { AnnotationSegFilter, AnnotationSegSort, SortValueOptions } from "../../types/AnnotationTypes";
import { Option } from "../../types/CommunitySortTypes";
import { FilterListResponse } from "../../AUTO_GENERATED_TYPES";
import isFilterFull from "../../assets/utils/isFilterFull";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { updateQueryParams } from "../../app/modules/Config/utils/paginationQuery";

const useAnnotation = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [urlUsed, setUrlUsed] = useState(false);
    const isSortingApplied = useRef(false);

    const {
        page,
        itemsPerPage,
        tagFilter,
        dataRequest,
        filters,
        filterCount,
        sorts,
        sortCount,
        imageType
    } = useSelector((state: RootState) => ({
        page: state.annotation.page,
        itemsPerPage: state.annotation.itemsPerPage,
        tagFilter: state.annotation.tagFilter,
        dataRequest: state.annotation.dataRequest,
        filters: state.annotation.filters,
        filterCount: state.annotation.filterCount,
        sorts: state.annotation.sorts,
        sortCount: state.annotation.sortCount,
        imageType: state.annotation.imageType
    }));

    const filterOutUnnecessaryOperators = (filters) => {
        return filters
            .filter((filter) => {
                return (
                    filter.filterValue &&
                    filter.filterValue.length > 0
                );
            })
            .map((filter) => {
                const {
                    id,
                    filterType,
                    filterValue,
                    filter_operator,
                    filter_operators,
                    dataType
                } = filter;
                return {
                    id,
                    filterType,
                    filterValue,
                    filter_operator,
                    filter_operators,
                    dataType,
                };
            });
    };

    const encodeSortsForUrl = (sorts) => {
        const serializedSorts = JSON.stringify(
            sorts.map(sort => ({
                id: sort.id,
                order_definition_id: Number(sort.sortType.value),
                order_label: sort.sortType.label,
                direction: sort.sortValue.value,
                direction_label: sort.sortValue.label
            }))
        );
        const compressedSorts = compressToEncodedURIComponent(serializedSorts);
    
        updateQueryParams(
            {   
                sorts: compressedSorts,
                segpage: page.toString()
            },
            setSearchParams
        );
    };    
    

    const encodeFiltersForUrl = (filters) => {
        const minimalFilters = filterOutUnnecessaryOperators(filters);

        const serializedFilters = JSON.stringify(minimalFilters);
        const compressedFilters = compressToEncodedURIComponent(serializedFilters);

        updateQueryParams(
            {   
                filters: compressedFilters,
                segpage: page.toString()
            },
            setSearchParams
        );
    };

    const decodeFiltersFromUrl = (filtersParam) => {
        try {
            const decompressedFilters = decompressFromEncodedURIComponent(filtersParam);
            return JSON.parse(decompressedFilters);
        } catch (error) {
            console.error("Error decoding filters from URL:", error);
            toast.error("Failed to load filters from URL.");
            return [];
        }
    };

    const decodeSortsFromUrl = (sortsParam) => {
        try {
            const decompressedSorts = decompressFromEncodedURIComponent(sortsParam);
            return JSON.parse(decompressedSorts).map(sort => ({
                id: sort.id,
                sortType: { 
                    value: String(sort.order_definition_id), 
                    label: sort.order_label
                },
                sortValue: { 
                    value: sort.direction, 
                    label: sort.direction_label
                }
            }));
        } catch (error) {
            console.error("Error decoding sorts from URL:", error);
            toast.error("Failed to load sorts from URL.");
            return [];
        }
    };
    
    const processFilters = (filtersParam: string | null) => {
        if (!filtersParam) return [];
        try {
            return decodeFiltersFromUrl(filtersParam).map((filter) => ({
                id: filter.id,
                filterType: filter.filterType,
                filter_operator: filter.filter_operator,
                filterValue: filter.filterValue,
                filter_operators: filter.filter_operators || [],
                dataType: filter.dataType || null,
            }));
        } catch (error) {
            console.error("Error decoding filters from URL:", error);
            toast.error("Failed to load filters from URL.");
            return [];
        }
    };

    const processSorts = (sortsParam: string | null) => {
        if (!sortsParam) return [];
        try {
            return decodeSortsFromUrl(sortsParam);
        } catch (error) {
            console.error("Error decoding sorts from URL:", error);
            toast.error("Failed to load sorts from URL.");
            return [];
        }
    };
    

    const updateDataRequest = () => {
        console.log('Filter Count: ', filterCount);
        console.log('Sort Count: ', sortCount);
        if (filterCount > 0 && !filters.every(isFilterFull) && urlUsed) {
            toast.warn('Please complete all filter fields.');
            return;
        }
        else if (filterCount > 0 || sortCount > 0) {
            dispatch(setAnnotationPage(1));

            setTimeout(() => {
                console.log("Updating Data Request", filters, sorts);
                const requestPayload: any = {};
                console.log('Filters:', filters);
                if (filterCount > 0) {
                    requestPayload.filters = filters.map(filter => ({
                        filter_definition_id: Number(filter.filterType.value),
                        filter_operator: filter.filter_operator.value,
                        filter_value: filter.filterValue.map(el => el.value),
                    })).filter(filter => filter.filter_value);
                }
                console.log('Sorts:', sorts);
                if (sortCount > 0) {
                    console.log('Sorts:', sorts);
                    requestPayload.orders = sorts.map(sort => ({
                        order_definition_id: Number(sort.sortType.value),
                        direction: sort.sortValue.value,
                    })).filter(order => order.order_definition_id);
                }
                dispatch(setAnnotationDataRequest(requestPayload));
            }, 300);
            if (filterCount > 0) {
                console.log('Encoding filters', filters);
                encodeFiltersForUrl(filters);
            }
            if (sortCount > 0) {
                console.log('Encoding sorts', sorts);
                encodeSortsForUrl(sorts);
            }
        }
    };

    useEffect(() => {
        const filtersParam = searchParams.get("filters");
        const sortsParam = searchParams.get("sorts");
        const segpageParam = searchParams.get("segpage");
    
        if (!urlUsed) {
            setUrlUsed(true);
            const decodedFilters = processFilters(filtersParam);
            const decodedSorts = processSorts(sortsParam);
    
            if (decodedFilters.length > 0 || decodedSorts.length > 0) {
                setUrlUsed(true);
                const initialPage = parseInt(segpageParam || "1", 10) - 1;

                const requestPayload: any = {};
    
                if (decodedFilters.length > 0) {
                    requestPayload.filters = decodedFilters.map((filter) => ({
                        filter_definition_id: Number(filter.filterType.value),
                        filter_operator: filter.filter_operator.value,
                        filter_value: filter.filterValue.map((el) => el.value),
                    }));
                    dispatch(setAnnotationFilters(decodedFilters));
                }

                if (decodedSorts.length > 0) {
                    decodedSorts.forEach((sort) => {
                        const formattedSort = {
                            id: sort.id,
                            sortType: { value: String(sort.sortType.value), label: sort.sortType.label },
                            sortValue: { value: sort.sortValue.value, label: sort.sortValue.label },
                        };
                        requestPayload.orders = [
                            {
                                order_definition_id: Number(formattedSort.sortType.value),
                                direction: formattedSort.sortValue.value,
                            },
                        ];
                    })
                }

                dispatch(setAnnotationPage(initialPage + 1));
                dispatch(setAnnotationDataRequest(requestPayload));
    
                if (decodedFilters.length > 0) {
                    setTimeout(() => {
                        const updatedFilters = decodedFilters.map((filter) => {
                            const existingFilter = filters.find((f) => f.id === filter.id);
                            if (existingFilter?.filterValues && existingFilter.filterValues.length > 1) {
                                return {
                                    ...filter,
                                    filterValues: existingFilter.filterValues,
                                };
                            }
                            return filter;
                        });
                        dispatch(setAnnotationFilters(updatedFilters));
                    }, 300);
                }
                if (decodedSorts.length > 0 && !isSortingApplied.current) {
                    isSortingApplied.current = true;
                    setTimeout(() => {
                        decodedSorts.forEach((sort) => {
                            const formattedSort = {
                                id: sort.id,
                                sortType: { value: String(sort.sortType.value), label: sort.sortType.label },
                                sortValue: { value: sort.sortValue.value, label: sort.sortValue.label },
                            };
                            dispatch(addAnnotationOrder(formattedSort));
                        });
                    }, 300);
                }
            }
        }
    }, []);
    

    // Tag Filter
    const changeTagFilter = (tag: string) => {
        console.log("Changing Tag Filter", tag);
        dispatch(setAnnotationTagFilter(tag));
    };

    // Pagination Functions
    const changePage = (page: number) => {
        dispatch(setAnnotationPage(page + 1));
    };

    const changeItemsPerPage = (perPage: number) => {
        console.log("Changing Items Per Page", perPage);
        dispatch(setAnnotationIPP(perPage));
    };

    // Sort Functions
    const addSort = (order?: AnnotationSegSort) => {
        console.log("Adding Sort", order);
        dispatch(addAnnotationOrder(order));
    };

    const removeSort = (id: string) => {
        console.log("Removing Sort", id);
        dispatch(removeAnnotationOrder(id));
    };

    const resetSort = () => {
        console.log("Resetting Sort");
        dispatch(resetAnnotationOrderBy());

        updateQueryParams({
            sorts: null
        }, setSearchParams);
    };

    const sortTypeChange = (id: string, value: Option) => {
        console.log("Changing Sort Type", id, value);
        dispatch(changeAnnotationOrderType(id, value));
    };

    const sortValueChange = (id: string, value: SortValueOptions) => {
        console.log("Changing Sort Value", id, value);
        dispatch(changeAnnotationOrderValue(id, value));
    };

    // Filter
    const filterReset = () => {
        console.log("Resetting Filter");
        dispatch(resetAnnotationFilter());
    };

    const setFilters = (annotations: AnnotationSegFilter[]) => {
        console.log("Setting Filters", annotations);
        dispatch(setAnnotationFilters(annotations));
    };

    const addFilter = (filter?: AnnotationSegFilter) => {
        console.log("Adding Filter", filter);
        dispatch(addAnnotationFilter(filter));
    };

    const removeFilter = (id: string) => {
        console.log("Removing Filter", id);
        dispatch(removeAnnotationFilter(id));
    };

    const filterTypeChange = (id: string, newValue: Option, meta: FilterListResponse, inDeletion?: boolean | undefined) => {
        console.log("Changing Filter Type", id, newValue, meta, inDeletion);
        dispatch(changeAnnotationFilterType(id, newValue, meta, inDeletion));
    };

    const filterOperatorChange = (id: string, newValue: Option) => {
        console.log("Changing Filter Operator", id, newValue);
        dispatch(changeAnnotationFilterOperator(id, newValue));
    };

    const filterValueChange = (id: string, newValue: Option, inDeletion?: boolean | undefined) => {
        console.log("Changing Filter Value", id, newValue, inDeletion);
        dispatch(changeAnnotationFilterValue(id, newValue, inDeletion));
    };

    const filterSAChange = (newValue: string, options: FilterListResponse | undefined) => {
        console.log("Changing Filter SA", newValue, options);
        dispatch(changeAnnotationFilterSA(newValue, options));
        updateDataRequest()
    };

    const changeImageType = (type: Option) => {
        console.log("Changing Image Type", type);
        dispatch(changeAnnotationImageType(type));
    };

    return {
        data: { page, itemsPerPage, tagFilter, dataRequest, filters, filterCount, sorts, sortCount, imageType },
        functions: {
            changePage,
            changeItemsPerPage,
            addSort,
            removeSort,
            resetSort,
            sortTypeChange,
            sortValueChange,
            updateDataRequest,
            changeTagFilter,
            filterReset,
            setFilters,
            addFilter,
            removeFilter,
            filterTypeChange,
            filterOperatorChange,
            filterValueChange,
            filterSAChange,
            changeImageType,
            encodeFiltersForUrl
        }
    };
};
export default useAnnotation;
