import classes from './AnnotationJobData.module.scss'
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {
    getAnnotationClasses,
    getAnnotationJobData,
    updateAnnotationTag
} from "../../../../../../_metronic/helpers/backend_helper";
import React, {useCallback, useState} from "react";
import {
    AnnotationClassesListResponse,
    AnnotationSegmentListResponse,
    AnnotationSegmentResponse,
    UpdateAnnotationRequest
} from "../../../../../../AUTO_GENERATED_TYPES";
import {Card} from "reactstrap";
import TaggingTooltip from "../../../../Community/Components/TaggingTooltip";
import SegmentDetailsPagination from "../../../../Community/Components/SegmentDetailsPagination";
import AnnotationTag from "../AnnotationTag/AnnotationTag";
import AnnotationDetailsModal from "../AnnotationDetailsModal/AnnotationDetailsModal";
import {toast} from "react-toastify";
import {debounce} from "lodash";
import {KTSVG} from "../../../../../../_metronic/helpers";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import AnnotationZoomModal from "../AnnotationZoomModal/AnnotationZoomModal";
import { useNavigate } from "react-router-dom";


const AnnotationJobData = () => {
    const { id } = useParams();
    const annotationId = Number(id);
    const queryClient = useQueryClient();
    
    const {
        data: { dataRequest, tagFilter, page, itemsPerPage, imageType },
        functions: { changePage }
    } = useAnnotation();

    const navigate = useNavigate();

    const [modalContext, setModalContext] = useState<AnnotationSegmentResponse | null>(null);
    const [zoomContext, setZoomContext] = useState<{ show: boolean, segment?: AnnotationSegmentResponse, index?: number }>({ show: false });
    const [tagUpdates, setTagUpdates] = useState<UpdateAnnotationRequest[]>([]);

    const { data, isLoading } = useQuery(
        [QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page],
        () => getAnnotationJobData(annotationId, dataRequest, tagFilter, itemsPerPage, page),
        {
            retry: false,
            refetchOnWindowFocus: false
        }
    );

    const { data: annotationClasses, isLoading: isClassesLoading } = useQuery(
        [QueryKeys.AnnotationJobClasses, annotationId],
        () => getAnnotationClasses(annotationId),
        {
            retry: false,
            staleTime: 5 * (60 * 1000)
        }
    );

    const debouncedHeaderInvalidation = useCallback(debounce(() => {
        setTagUpdates([])
        queryClient.invalidateQueries([QueryKeys.Annotation, QueryKeys.AnnotationJobHeader])
        toast.success('Tags Updated!', {autoClose: 1000})
    }, 3000), [])

    const debouncedTagging = useCallback(debounce((tags: UpdateAnnotationRequest[], requestBody: UpdateAnnotationRequest[]) => {
        const oldCopy = [...tags].filter(tag => tag.seg_id_list[0] !== requestBody[0].seg_id_list[0])
        const newRequest = [...oldCopy, ...requestBody]

        return updateAnnotationTag(annotationId, {update_list: newRequest})
    }, 3000), [])

    const {
        mutate: sendTagRequest,
        reset: cancelTagRequests
    } = useMutation<any, unknown, Array<UpdateAnnotationRequest>, () => void>((requestBody) => {
        const segmentIndex = data!.segments!.findIndex(value => value.id === requestBody[0].seg_id_list[0])
        const deepCopyData = JSON.parse(JSON.stringify(data)) as AnnotationSegmentListResponse
        const classes = queryClient.getQueryData<AnnotationClassesListResponse>([QueryKeys.AnnotationJobClasses, annotationId])


        const newTag = requestBody[0].segment_tag
        deepCopyData.segments![segmentIndex].tag = newTag

        const oldName = deepCopyData.segments![segmentIndex].tag_name
        deepCopyData.segments![segmentIndex].tag_name = classes?.classes.find(segClass => segClass.class_code === newTag)?.class_name ?? oldName

        queryClient.setQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page], deepCopyData)

        setTagUpdates(prevState => {
            const oldCopy = [...prevState].filter(tag => tag.seg_id_list[0] !== requestBody[0].seg_id_list[0])
            return [...oldCopy, ...requestBody]
        })

        return debouncedTagging(tagUpdates, requestBody) as any
    }, {
        onMutate: (variables => {
            return () => {
                toast.error(`Something went wrong, rolling back changes for Tag id: ${variables[0].seg_id_list[0]}`)
                queryClient.setQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page], data)
            }
        }),
        onSuccess: debouncedHeaderInvalidation,
        onError: (error, variables, context) => {
            if (context) {
                context()
                setTagUpdates([])
            }
        }
    })


    if (isLoading || isClassesLoading || !data) {
        return null
    }

    const tagHandler = (requestBody: UpdateAnnotationRequest[]) => {
        cancelTagRequests()
        sendTagRequest(requestBody)
    }


    const openModalHandler = (segment?: AnnotationSegmentResponse) => {
        if (segment) {
            setModalContext(segment)
        }
    }

    const toggleZoom = (segment?: AnnotationSegmentResponse | React.KeyboardEventHandler<any> | React.MouseEventHandler<any> | undefined) => {
        const argIsSeg = (seg: any): seg is AnnotationSegmentResponse => {
            return !!seg?.tag;
        }

        setZoomContext(prevState => {
            if (argIsSeg(segment)) {
                const annotationIndex = (JSON.parse(JSON.stringify(queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page]))) as AnnotationSegmentListResponse)?.segments?.findIndex((seg => seg.id === segment?.id))

                return {show: true, segment, index: annotationIndex}
            }

            if (prevState.show) {
                return {show: false}
            }

            return {show: false}
        })
    }

    const showZoomModal = (segment?: AnnotationSegmentResponse) => {
        setModalContext(null);
        const annotationIndex = (JSON.parse(JSON.stringify(queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page]))) as AnnotationSegmentListResponse)?.segments?.findIndex((seg => seg.id === segment?.id))
        setZoomContext({
            show: true,
            segment: segment,
            index: annotationIndex
        });
    };

    const closeModalHandler = () => setModalContext(null)

    const changePageHandler = (newPage: number) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("segpage", newPage.toString());
        navigate(`?${queryParams.toString()}`, { replace: true });
        changePage(newPage - 1);
    };

    return <>
        <Card className={classes.container}>
            <div className={classes.images}>
                {(data?.segments && data?.segments?.length > 0) ?
                    data.segments?.map((segment, index) => <div key={index}
                                                                className={'seg_img_exemple'}>
                        <TaggingTooltip placement={'auto'}
                                        tooltip={<>
                                            <p>Seg Id : <i>{segment.seg_id}</i></p>
                                            <p>Seg Count : <i>{segment.cnt_segments}</i></p>
                                            <p>Auth Inference : <i>{segment.auth_inference}</i></p>
                                            <p>Emu Inference : <i>{segment.emu_inference}</i></p>
                                            <p>Seg User Id : <i>{segment.user_id}</i></p>
                                            <p>Seg Session Id : <i>{segment.session_id}</i></p>
                                            {segment.comment && <p>Comment : <i>{segment.comment}</i></p>}
                                        </>}
                        >
                            <div className={classes.images__image_container}>
                                <img
                                    onClick={toggleZoom.bind(null, segment)}
                                    src={((segment?.plots && imageType.value) ? segment?.plots[imageType.value as keyof typeof segment] : Object.values(segment?.plots ?? {})[0]) as string}
                                    width={500} height={300}
                                    alt="segment_graph"/>
                                <div className={classes.images__tagging}>
                                    {annotationClasses?.classes.map((annotationClass, index) =>
                                        <AnnotationTag key={index} annotationClass={annotationClass} segId={segment.id}
                                                       isSelected={segment.tag === annotationClass.class_code}
                                                       onClick={tagHandler}/>)}
                                    <span onClick={openModalHandler.bind(null, segment)} className={classes.mag_glass}
                                    >
                                <KTSVG
                                    path='/media/icons/duotune/general/gen004.svg'
                                    className={'svg-icon-edit svg-icon-2qx cursor-pointer'}
                                />
                            </span>
                                </div>
                            </div>
                        </TaggingTooltip>

                    </div>) : <div className={classes.no_results}>No Results Found</div>}
            </div>
            {data?.paging?.total_items ? <SegmentDetailsPagination currentPage={data?.paging?.page ?? 1}
                                                                   pageCount={data?.paging.num_of_pages ?? 1}
                                                                   handlePaginate={(({selected}) => changePage(selected))}/> : null}
        </Card>
        <AnnotationZoomModal openModalHandler={openModalHandler} annotationClasses={annotationClasses}
                             setZoomContext={setZoomContext} zoomContext={zoomContext} tagHandler={tagHandler}
                             toggleZoom={toggleZoom} currPage={data?.paging?.page ?? 1} pages={data?.paging.num_of_pages ?? 1} changePage={changePageHandler}/>
        <AnnotationDetailsModal closeHandler={closeModalHandler} segment={modalContext} changeSegment={openModalHandler} showZoomModal={showZoomModal}
                                currPage={data?.paging?.page ?? 1} pages={data?.paging.num_of_pages ?? 1} changePage={changePageHandler}/>
    </>
}

export default AnnotationJobData
