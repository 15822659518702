import React, {useEffect, useState} from 'react';
import {PageTitle} from "../../../../_metronic/layout/core";
import {getSegmentData} from "../../../../store/community/actions";
import {
    FilterDataType,
    FilterDefinitionListResponse,
    FilterListResponse,
    SegmentType,
    TagFilter
} from "../../../../AUTO_GENERATED_TYPES";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {Button, Col, Row} from "react-bootstrap";
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Label, UncontrolledTooltip} from "reactstrap";
import ReactPaginate from "react-paginate";
import SegmentTaggingFilter from "../Modals/SegmentTaggingFilter";
import {KTSVG} from "../../../../_metronic/helpers";
import tableClasses from './table.module.scss'
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import useCommunityFilters from "../../../../store/community-filters/useCommunityFilters";
import {addFilterFromDetails, resetFilters} from "../../../../store/community-filters/actions";
import SegmentTaggingSort from "../Modals/SegmentTaggingSort";
import useCommunitySort from "../../../../store/community-sort/useCommunitySort";
import {resetSort} from "../../../../store/community-sort/actions";
import useGetFiltersAndOrders from "../../../../store/community/useGetFiltersAndOrders";
import SweetAlert from "react-bootstrap-sweetalert";
import CreatableSelect from "react-select/creatable";
import QueryKeys from "../../../../react-query/QueryKeys";
import {v4 as uuidv4} from "uuid";
import {getSegmentFilters} from "../../../../_metronic/helpers/backend_helper";
import {useIsFetching, useQueryClient} from "react-query";
import getFilterIdByColumnName from "../../../../assets/utils/getFilterIdByColumnName";

const ItemsTable = () => {

    const dispatch = useDispatch()
    const {SearchBar} = Search;

    const [itemPerPage, setItemPerPage] = useState<number>(100);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(10)
    const [itemPerPageLimit, setItemPerPageLimit] = useState<boolean>(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [showSort, setShowSort] = useState(false)

    const {filterCount} = useCommunityFilters()
    const {sortCount} = useCommunitySort(false)
    const queryClient = useQueryClient()

    const {segmentData, result} = useSelector((state: RootState) => ({
        segmentData: state.community.segmentData,
        result: state.community.result,
    }))

    const {jobId: segId, type: segType} = useParams()

    const filterRequestBody = useGetFiltersAndOrders()
    const {onlyFullFilters} = useCommunityFilters()

    const isFetching = useIsFetching()

    useEffect(() => {
        const closeFilter = (e: any) => {
            if (e.target.closest('.segment_filter_window')) {
                console.log(e)
            } else {
                setIsFilterOpen(false)
            }
        }

        document.body.addEventListener('click', closeFilter)

        return () => {
            document.body.removeEventListener('click', closeFilter)
            dispatch(resetFilters())
            dispatch(resetSort())
        }
    }, [])


    useEffect(() => {
        dispatch(getSegmentData(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, filterRequestBody, itemPerPage, currentPage))
    }, [itemPerPage, currentPage]);

    useEffect(() => {
        if (result === true) {
            dispatch(getSegmentData(Number(segId), segType === 'positive' ? SegmentType.POS : SegmentType.NEG, {
                "filters": [],
                "orders": []
            }, itemPerPage, currentPage))
        }
    }, [result]);

    useEffect(() => {
        setPageCount(Math.ceil(segmentData?.paging?.total_items / segmentData?.paging?.items_per_page))
    }, [segmentData]);


    const dateFormatter = (data: any) => {
        let date = data
            ?.replace(/[-]/g, '/')
            ?.replace(/[T]/g, '\u00A0');
        return date?.slice(0, 16);

    }


    const copyFormatter = (data: any, row: any, i: any, additionalData?: any) => {
        const filterId = getFilterIdByColumnName(additionalData, segType);
        const filtersDef = queryClient.getQueryData<FilterDefinitionListResponse>([QueryKeys.FilterDefinition, segType])?.filters ?? []
        const currentFilter = filtersDef.find(filter => filter.id === filterId)
        const filterIsJson = currentFilter?.data_type === FilterDataType.JSON

        const fetchFilteredData = (value: any, type: string, filterType: number) => {
            const segmentType = segType === 'positive' ? SegmentType.POS : SegmentType.NEG;
            const filters = filterRequestBody?.filters ?? []
            const orders = filterRequestBody?.orders ?? []


            if (filters.length === 5) {
                toast.error('ERROR')
                return
            }

            const body = {filters, orders}

            const label = currentFilter?.short_name ?? ''

            const newFilter = {
                id: uuidv4(),
                filterType: {value: filterType + '', label: label},
                filterValue: [{value, label: value}],
                filterValues: [{value: "", label: ""}],
                filter_operator: {value: "==", label: "=="},
                filter_operators: [{value: "", label: ""}],
                dataType: null as any
            }

            const meta = queryClient.getQueryData([QueryKeys.SegmentFilters, {
                segId,
                value: value,
                segType,
                onlyFullFilters,
                tagFilter: TagFilter.ALL
            }]) as FilterListResponse
            if (meta) {
                const valuesOptions = meta.filter_list?.map(filter => ({
                    value: filter.filter_name,
                    label: `${filter.filter_name} (${filter.filter_counter})`
                }))
                newFilter.filterValues = valuesOptions ?? [{value, label: value}]
                newFilter.filter_operators = meta.filter_operator_values?.map(value => ({
                    value,
                    label: value
                })) ?? [{value: "", label: ""}]
                newFilter.filterValue = [valuesOptions?.find(option => option.value === (filterIsJson ? JSON.stringify(value) : value)) ?? newFilter.filterValue].flat()
                newFilter.dataType = meta.filter_data_type
                if (newFilter.dataType === FilterDataType.STRING) {
                    newFilter.filter_operator = {value: 'is in', label: 'is in'}
                }
            } else {
                getSegmentFilters(Number(segId), filterType, segmentType, onlyFullFilters, TagFilter.ALL).then(response => {
                    queryClient.setQueryData([QueryKeys.SegmentFilters, {
                        segId,
                        value: filterType,
                        segType,
                        onlyFullFilters
                    }], response)
                    const valuesOptions = response.filter_list?.map(filter => ({
                        value: filter.filter_name,
                        label: `${filter.filter_name} (${filter.filter_counter})`
                    }))
                    newFilter.filterValues = valuesOptions ?? [{value, label: value}]
                    newFilter.filter_operators = response.filter_operator_values?.map(value => ({
                        value,
                        label: value
                    })) ?? [{value: "", label: ""}]
                    newFilter.filterValue = [valuesOptions?.find(option => option.value === (filterIsJson ? JSON.stringify(value) : String(value))) ?? newFilter.filterValue].flat()
                    newFilter.dataType = response.filter_data_type
                    if (newFilter.dataType === FilterDataType.STRING) {
                        newFilter.filter_operator = {value: 'is in', label: 'is in'}
                    }

                }).catch(() => toast.error('Something went wrong! Please try again.'))
            }

            const newReqFilter = {
                filter_definition_id: Number.parseInt(newFilter.filterType.value),
                filter_operator: newFilter.filter_operator.value,
                filter_value: newFilter.filterValue.map(filter => filter.value)
            }

            if(filterIsJson && newReqFilter.filter_value[0]){
                newReqFilter.filter_value = [JSON.stringify(newReqFilter.filter_value[0])]
            }

            body.filters.push(newReqFilter)

            const fetchData = {
                cdId: Number(segId),
                segType: segmentType,
                body,
                itemPp: itemPerPage,
                page: currentPage,
                // tag: taggingValue
            }

            dispatch(addFilterFromDetails(newFilter, fetchData))
        }


        const handleFilter = () => {
            if ((!data && data !== 0 && data !== '0') || !filterId) {
                return
            }
            fetchFilteredData(data, additionalData, filterId)
        }

        const handleCopy = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            navigator.clipboard.writeText(filterIsJson ? JSON.stringify(data) : String(data)).then(() => {
                toast.info('Copied to clipboard!')
            })
        }

        const dataIsArray = Array.isArray(data);
        let renderData = data

        if (typeof renderData === 'number') {
            renderData = renderData.toLocaleString()
        } else if (dataIsArray) {
            renderData = data.join(',')
        }


        return <div className={'d-flex justify-content-between'} title={renderData}
                    style={renderData && filterId ? {cursor: 'pointer'} : undefined} onClick={handleFilter}>
            <div className={'seg_long_copy'}>{renderData ?? 'N/A'}</div>
            {!!data && <div className={'copy_btn'} onClick={handleCopy}><KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className='svg-icon-edit svg-icon-2'
                cache
            />
            </div>}

        </div>

    }

    const handlePaginate = (selectedItem: any) => {
        setCurrentPage(selectedItem.selected + 1)
    }

    const handleItemsPerPage = (count: number) => {
        if (!count) {
            setItemPerPageLimit(true)
        } else {
            setItemPerPage(count)
            setCurrentPage(1)
        }

    }

    const columns = [
        {
            dataField: 'index',
            hidden: false,
            text: 'Index',
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'tag',
            text: 'Tag',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.tag === 'POS' ? '+' : row.tag === 'NEG' ? '-' : row.tag === 'UN_CERTAIN' ? '?' : ''}</span>
                </>
            )
        },
        {
            dataField: 'community_id',
            text: 'Community',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'community_id'
        },
        {
            dataField: 'auth_inference',
            text: 'Auth Inf',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'auth_inference'
        },
        {
            dataField: 'auth_score',
            text: 'Auth Score',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'auth_score'
        },
        {
            dataField: 'cnt_motion_down',
            text: '#Motion Down',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cnt_motion_down'
        },
        {
            dataField: 'cnt_segments',
            text: '#Seg In Session',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cnt_segments'
        },
        {
            dataField: 'cnt_seq_acc',
            text: '#Seq Acc',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cnt_seq_acc'
        },
        {
            dataField: 'cnt_seq_motion',
            text: '#Seq Motion',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cnt_seq_motion'
        },
        {
            dataField: 'emu_score',
            text: 'Emu Score',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'emu_score'
        },
        {
            dataField: 'input_devices_inference',
            text: 'Input devices inference',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'input_devices_inference'
        },
        {
            dataField: 'input_devices_found',
            text: 'Input devices found',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'input_devices_found'
        },
        {
            dataField: 'labeled_auth_label',
            text: 'Labeled Auth Label',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'labeled_auth_label'
        },
        {
            dataField: 'max_group_similarity',
            text: 'Max Group Sim',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'max_group_similarity'
        },
        {
            dataField: 'min_group_similarity',
            text: 'Min Group Sim',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'min_group_similarity'
        },
        {
            dataField: 'screen_height',
            text: 'Screen Height',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'screen_height'
        },
        {
            dataField: 'screen_width',
            text: 'Screen Width',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'screen_width'
        },
        {
            dataField: 'seg_count',
            text: 'Seg Count',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'seg_count'
        },
        {
            dataField: 'seg_duration_millis',
            text: 'Seg Duration (mills)',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'seg_duration_millis'
        },
        {
            dataField: 'additional_id',
            text: 'Additional Id',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'additional_id'

        },
        {
            dataField: 'device_id',
            text: 'Device Id',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device_id'
        },
        {
            dataField: 'auth_models[0]',
            text: 'Auth Models',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'auth_models'

        },
        {
            dataField: 'country_code',
            text: 'Country',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'country_code'

        },

        {
            dataField: 'emu_models',
            text: 'Emu Models',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'emu_models',

        },
        {
            dataField: 'group',
            text: 'Group',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'group'

        },
        {
            dataField: 'ip_address',
            text: 'IP Address',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'ip_address'

        },
        {
            dataField: 'ip_network',
            text: 'IP Network',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'ip_network'

        },
        {
            dataField: 'model',
            text: 'Model',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'model'

        },
        {
            dataField: 'system_version',
            text: 'System Version',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'system_version'

        },
        {
            dataField: 'seg_id',
            text: 'Seg Id',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'seg_id'

        },
        {
            dataField: 'segment_ids',
            text: 'Segment Ids',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'segment_ids'

        },
        {
            dataField: 'seg_name',
            text: 'Seg Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'seg_name'

        },
        {
            dataField: 'session_id',
            text: 'Session Id',
            editable: false,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '355px'};
            },
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'session_id'

        },
        {
            dataField: 'user_id',
            text: 'User Id',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'user_id'

        },
        {
            dataField: 'sdk_version_name',
            text: 'SDK Ver. Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'sdk_version_name'
        },
        {
            dataField: 'device',
            text: 'Device',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device'
        },
        {
            dataField: 'cnt_motion_move',
            text: '#Motion Move',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cnt_motion_move'
        },
        {
            dataField: 'most_common_xy',
            text: 'Most Common XY',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'most_common_xy'
        },
        {
            dataField: 'ts_start',
            text: 'TS Start',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'ts_start'
        },
        {
            dataField: 'key_values',
            text: 'Key Values',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'key_values'
        },
        {
            dataField: 'cnt_unique_xy',
            text: 'Count Unique XY',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'cnt_unique_xy'
        },
        {
            dataField: 'additional_info',
            text: 'Additional Info',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'additional_info'
        },
        {
            dataField: 'extended_info',
            text: 'Extended Info',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'extended_info'
        },
        {
            dataField: 'bio_duration_millis',
            text: 'Bio Durations Millis',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'bio_duration_millis'
        }, {
            dataField: 'auth_inference_canary',
            text: 'Auth Inference Canary',
            editable: false,
            sort: true,
            formatter: copyFormatter,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatExtraData: 'auth_inference_canary'
        },
        {
            dataField: 'rooted_inference',
            text: 'Rooted Inference',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'rooted_inference'
        },
        {
            dataField: 'auth_user_inference',
            text: 'Auth User Inference',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'auth_user_inference'
        },
        {
            dataField: 'app_package_name',
            text: 'App Package Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'app_package_name'
        },
        {
            dataField: 'app_version_code_str',
            text: 'App Version Code',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'app_version_code_str'
        },
        {
            dataField: 'cid',
            text: 'CID',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'cid'
        },
        {
            dataField: 'component_device_count',
            text: 'Component Device Count',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'component_device_count'
        },
        {
            dataField: 'component_user_count',
            text: 'Component User Count',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'component_user_count'
        },
        {
            dataField: 'component_automation_count',
            text: 'Component Automation Count',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'component_automation_count'
        },
        {
            dataField: 'component_inhuman_count',
            text: 'Component Inhuman Count',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'component_inhuman_count'
        },
        {
            dataField: 'component_auth',
            text: 'Component Auth',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'component_auth'
        },
        {
            dataField: 'is_timestamp_anomaly',
            text: 'Timestamp Anomaly',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'is_timestamp_anomaly'
        },
        {
            dataField: 'device_type',
            text: 'Device Type',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device_type'
        },
        {
            dataField: 'device_brand',
            text: 'Device Brand',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device_brand'
        },
        {
            dataField: 'device_manufacturer',
            text: 'Device Manufacturer',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device_manufacturer'
        },
        {
            dataField: 'device_marketing_name',
            text: 'Device Marketing Name',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: copyFormatter,
            formatExtraData: 'device_marketing_name'
        },

    ];

    return (
        <>
            <div className={'items_table'}>
                <PageTitle>{'Items Table'}</PageTitle>
                <ToolkitProvider
                    keyField="id"
                    data={segmentData.segments || []}
                    columns={columns}
                    search
                    bootstrap4
                >
                    {
                        (props: any) => (
                            <div style={{padding: '15px'}}>
                                <div>
                                    {segmentData?.segments &&
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start'
                                        }}>
                                            <p>Creation Time
                                                : <b>{dateFormatter(segmentData?.segments[0]?.creation_time)}</b></p>
                                            <p style={{marginLeft: '10px'}}>Type
                                                : <b>{segmentData?.segments[0]?.type}</b></p>
                                            <div className={tableClasses.hint} id={'hint'}><KTSVG
                                                path='/media/icons/duotune/general/gen045.svg'
                                                className='svg-icon-edit svg-icon-2'
                                            />
                                                <UncontrolledTooltip target={'hint'} placement={'right'}
                                                                     style={{textAlign: 'start'}}>Click on a cell
                                                    to filter by it. Click <KTSVG
                                                        path='/media/icons/duotune/general/gen054.svg'
                                                        className='svg-icon-edit svg-icon-2'
                                                    /> to copy the value.</UncontrolledTooltip></div>
                                        </div>
                                    }
                                    <br/>
                                    <div className={'toolbar_table_community'}>
                                        <div>
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <Button className={'position-relative'}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    setIsFilterOpen(prevState => !prevState)
                                                    setShowSort(false)
                                                }}>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen031.svg'
                                                className='svg-icon-edit svg-icon-2'
                                            />
                                            Filter {filterCount ? `(${filterCount})` : ''}
                                            <div><SegmentTaggingFilter isOpen={isFilterOpen}
                                                                       itemPerPage={itemPerPage}
                                                                       currentPage={currentPage}
                                                                       onClose={() => setIsFilterOpen(false)}
                                                                       isItemTable={true}
                                                                       resetPage={() => setCurrentPage(1)}
                                                                       tagFilter={TagFilter.ALL}
                                            />

                                            </div>
                                        </Button>
                                        <Button className={'position-relative'} onClick={(e) => {
                                            e.stopPropagation()
                                            setShowSort(prevState => !prevState)
                                            setIsFilterOpen(false)
                                        }}>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen032.svg'
                                                className='svg-icon-edit svg-icon-2'
                                            />
                                            Sort {Boolean(sortCount) && <span>({sortCount})</span>}
                                            <SegmentTaggingSort isOpen={showSort} setIsOpen={setShowSort}
                                                                itemPerPage={itemPerPage}
                                                                currentPage={currentPage}/>
                                        </Button>

                                        <div className={'toolbar_table_community__perPage'}
                                             style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                            <Label style={{marginBottom: 0}}>Per Page</Label>
                                            <CreatableSelect value={{value: itemPerPage, label: itemPerPage}}
                                                             onChange={(e) => handleItemsPerPage(Number(e?.value))}
                                                             options={[10, 25, 50, 100].map(num => ({
                                                                 value: num,
                                                                 label: num
                                                             }))}
                                                             formatCreateLabel={(inputValue: string) => inputValue}
                                                             createOptionPosition={'first'}
                                                             isValidNewOption={inputValue => !isNaN(parseInt(inputValue)) && parseInt(inputValue) > 0}

                                            />
                                            <Label
                                                style={{marginBottom: 0}}>{segmentData?.paging?.total_items || 0} Total</Label>

                                        </div>
                                    </div>
                                    <br/>
                                </div>
                                <Row>
                                    <Col xl='12' className={tableClasses.table_wrapper}>
                                        {segmentData?.segments?.length !== 0 && !isFetching ? <BootstrapTable
                                            {...props.baseProps}
                                            keyField='id'
                                            bordered={true}
                                            responsive
                                            stripped
                                            headerWrapperClasses={"header-class"}
                                        /> : <div className={'text-center'}>{isFetching ? '' : 'No Data Found'}</div>}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            {segmentData?.segments?.length !== 0 && <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={handlePaginate}
                forcePage={currentPage - 1}
            />}
            {/*@ts-ignore*/}
            <SweetAlert
                show={itemPerPageLimit}
                warning
                confirmBtnText="Okay"
                confirmBtnBsStyle={'danger'}
                title={`Warning`}
                onConfirm={() => {
                    setItemPerPageLimit(false)
                }
                }
            >
                <p>Items per page must be greater than 0</p>
            </SweetAlert>
        </>
    )
}

export default ItemsTable
