import {Modal} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import React, {useEffect} from "react";
import {Label} from "reactstrap";
import './community-modals.scss'
import styles from "../../../general-styles/Modals/modals.module.scss";
import {
    FilterDataType,
    FilterDefinitionListResponse,
    FilterListResponse,
    SegmentType
} from "../../../../AUTO_GENERATED_TYPES";
import {useDispatch} from "react-redux";
import {useQueryClient} from "react-query";
import QueryKeys from "../../../../react-query/QueryKeys";
import LongDetailCell from "../Components/LongDetailCell";
import {addFilterFromDetails} from "../../../../store/community-filters/actions";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {getSegmentFilters} from "../../../../_metronic/helpers/backend_helper";
import {toast} from "react-toastify";
import useGetFiltersAndOrders from "../../../../store/community/useGetFiltersAndOrders";
import useCommunityFilters from "../../../../store/community-filters/useCommunityFilters";
import getFilterIdByColumnName from "../../../../assets/utils/getFilterIdByColumnName";
import replaceComma from "../../../../assets/utils/replaceStringComma";

interface DetailsModalProps {
    show: boolean,
    handleCloseModal: any,
    data: any,
    segId: any,
    currentPage: any,
    itemsPerPage: any,
    segType: any,
    taggingValue: any,
    title: string,
    closeModal?: Function,
    setTagDefault?: Function
    segments: any,
    setSegment: (seg: any) => void

}

const DetailsModal = ({
                          show,
                          handleCloseModal,
                          data,
                          segType,
                          segId,
                          currentPage,
                          itemsPerPage,
                          taggingValue,
                          closeModal,
                          setTagDefault,
                          segments,
                          setSegment
                      }: DetailsModalProps) => {
    const queryClient = useQueryClient()
    const communityId = queryClient.getQueryData<FilterDefinitionListResponse>([QueryKeys.FilterDefinition])?.filters?.find(desc => desc.column_name === 'community_id')?.id ?? 29
    const segmentIndex = segments?.findIndex((seg => seg.id === data?.id))

    const filterRequestBody = useGetFiltersAndOrders()
    const {onlyFullFilters} = useCommunityFilters()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const dateFormatter = (data: any) => {
        let date = data
            .replace(/[-]/g, '/')
            .replace(/[T]/g, '\u00A0');
        let jsDate = date.slice(0, 16);
        return jsDate
    }

    const fetchFilteredData = (value: any, type: string) => {
        if (setTagDefault)
            setTagDefault()

        const segmentType = segType === 'positive' ? SegmentType.POS : SegmentType.NEG;
        const filterType = getFilterIdByColumnName(type, segType)!;
        const filters = filterRequestBody?.filters ?? []
        const orders = filterRequestBody?.orders ?? []

        if (filters.length === 5) {
            toast.error('ERROR')
            handleCloseModal()
            if (closeModal) {
                closeModal()
            }
            return
        }

        const body = {filters, orders}

        const filtersDef = queryClient.getQueryData<FilterDefinitionListResponse>([QueryKeys.FilterDefinition, segType])?.filters ?? []
        const label = filtersDef.find(filter => filter.id === filterType)!.short_name

        const newFilter = {
            id: uuidv4(),
            filterType: {value: filterType + '', label: label},
            filterValue: [{value, label: value}],
            filterValues: [{value: "", label: ""}],
            filter_operator: {value: "==", label: "=="},
            filter_operators: [{value: "", label: ""}],
            dataType: null as any
        }

        const meta = queryClient.getQueryData([QueryKeys.SegmentFilters, {
            segId,
            value: value,
            segType,
            onlyFullFilters,
            tagFilter: taggingValue
        }]) as FilterListResponse
        if (meta) {
            const valuesOptions = meta.filter_list?.map(filter => ({
                value: filter.filter_name,
                label: `${filter.filter_name} (${filter.filter_counter})`
            }))
            newFilter.filterValues = valuesOptions ?? [{value, label: value}]
            newFilter.filter_operators = meta.filter_operator_values?.map(value => ({
                value,
                label: value
            })) ?? [{value: "", label: ""}]
            newFilter.filterValue = [valuesOptions?.find(option => option.value === value) ?? newFilter.filterValue].flat()
            newFilter.dataType = meta.filter_data_type
            if (newFilter.dataType === FilterDataType.STRING) {
                newFilter.filter_operator = {value: 'is in', label: 'is in'}
            }
        } else {
            getSegmentFilters(Number(segId), filterType, segmentType, onlyFullFilters, taggingValue).then(response => {
                queryClient.setQueryData([QueryKeys.SegmentFilters, {
                    segId,
                    value: filterType,
                    segType,
                    onlyFullFilters,
                    tagFilter: taggingValue
                }], response)
                const valuesOptions = response.filter_list?.map(filter => ({
                    value: filter.filter_name,
                    label: `${filter.filter_name} (${filter.filter_counter})`
                }))
                newFilter.filterValues = valuesOptions ?? [{value, label: value}]
                newFilter.filter_operators = response.filter_operator_values?.map(value => ({
                    value,
                    label: value
                })) ?? [{value: "", label: ""}]
                newFilter.filterValue = [valuesOptions?.find(option => option.value === String(value)) ?? newFilter.filterValue].flat()
                newFilter.dataType = response.filter_data_type
                if (newFilter.dataType === FilterDataType.STRING) {
                    newFilter.filter_operator = {value: 'is in', label: 'is in'}
                }

            }).catch(() => toast.error('Something went wrong! Please try again.'))
        }

        const newReqFilter = {
            filter_definition_id: Number.parseInt(newFilter.filterType.value),
            filter_operator: newFilter.filter_operator.value,
            filter_value: newFilter.filterValue.map(filter => filter.value)
        }

        body.filters.push(newReqFilter)

        const fetchData = {
            cdId: Number(segId),
            segType: segmentType,
            body,
            itemPp: itemsPerPage,
            page: currentPage,
            tag: taggingValue
        }

        dispatch(addFilterFromDetails(newFilter, fetchData))
        if (filterType === communityId) {
            navigate(`/community/job/segment/${segType}/${segId}/${value}`)
        }

        handleCloseModal()
        if (closeModal) {
            closeModal()
        }
    }


    useEffect(() => {
        let handleNavigation: any;

        if (segmentIndex !== -1 && segments && show) {
            const previous = segments[(segmentIndex ?? 0) - 1]
            const next = segments[(segmentIndex ?? 0) + 1]

            handleNavigation = (event: KeyboardEvent) => {
                if (event.key === 'ArrowRight' && next) {
                    setSegment(next)
                } else if (event.key === 'ArrowLeft' && previous) {
                    setSegment(previous)

                }
            }

            document.addEventListener('keydown', handleNavigation)

            return () => {
                document.removeEventListener('keydown', handleNavigation)
            }
        }
    }, [segmentIndex, segments, show])

    return (
        <>
            {data &&
                <Modal show={show}
                       onHide={handleCloseModal}
                       centered
                       size="xl"
                >
                    <div>
                        <div className='modal-content'>
                            <div className={styles.flex_row_between}>
                                <div className={styles.title_close_space}>
                                    <h4>Details for <span
                                        style={{
                                            fontStyle: 'italic',
                                            fontWeight: 'normal'
                                        }}>{data.seg_id ?? 'N/A'}</span> <span
                                        style={{
                                            marginLeft: '20px',
                                            fontStyle: 'italic',
                                            fontWeight: 'normal'
                                        }}>{dateFormatter(data.creation_time ?? 'N/A')} <span
                                        className={'tag_border'}>{data.tag ?? 'N/A'}</span></span></h4>
                                </div>
                                <div className='modal_close_btn'>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary'
                                         onClick={handleCloseModal}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className={'img_details'}>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Segment Id</Label>
                                    <LongDetailCell data={data.seg_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.seg_id, 'seg_id')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Country</Label>
                                    <LongDetailCell data={data.country_code ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.country_code, 'country_code')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Additional Id</Label>
                                    <LongDetailCell data={data.additional_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.additional_id, 'additional_id')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device Id</Label>
                                    <LongDetailCell data={data.device_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device_id, 'device_id')}/>
                                </div>

                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Community Id</Label>
                                    <LongDetailCell data={data.community_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.community_id, 'community_id')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Screen Width</Label>
                                    <LongDetailCell data={data.screen_width ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.screen_width, 'screen_width')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Screen Height</Label>
                                    <LongDetailCell data={data.screen_height ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.screen_height, 'screen_height')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> IP Network</Label>
                                    <LongDetailCell data={data.ip_network ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.ip_network, 'ip_network')}/>
                                </div>

                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> IP Address</Label>
                                    <LongDetailCell data={data.ip_address ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.ip_address, 'ip_address')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Model</Label>
                                    <LongDetailCell data={data.model ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.model, 'model')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Auth Model</Label>
                                    <LongDetailCell data={replaceComma(JSON.stringify(data.auth_models)).replaceAll(',[',', [') ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(replaceComma(JSON.stringify(data.auth_models)).replaceAll(',[',', ['), 'auth_models')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Emu Models</Label>
                                    <LongDetailCell data={replaceComma(JSON.stringify(data.emu_models)).replaceAll(',[',', [') ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(replaceComma(JSON.stringify(data.emu_models)).replaceAll(',[',', ['), 'emu_models')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Input Devices Found</Label>
                                    <LongDetailCell data={replaceComma(JSON.stringify(data.input_devices_found)).replaceAll(',[',', [') ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(replaceComma(JSON.stringify(data.input_devices_found)).replaceAll(',[',', ['), 'input_devices_found')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> System Version</Label>
                                    <LongDetailCell data={data.system_version ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.system_version, "system_version")}/>
                                </div>
                                <div>
                                    <Label> <KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Segment Name</Label>
                                    <LongDetailCell data={data.seg_name ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.seg_name, 'seg_name')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Session Id</Label>
                                    <LongDetailCell data={data.session_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.session_id, 'session_id')}/>
                                </div>

                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> User Id</Label>
                                    <LongDetailCell data={data.user_id ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.user_id, 'user_id')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> SDK Ver. Name</Label>
                                    <LongDetailCell data={data.sdk_version_name ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.sdk_version_name, 'sdk_version_name')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device</Label>
                                    <LongDetailCell data={data.device ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device, 'device')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> TS Start</Label>
                                    <LongDetailCell data={data.ts_start ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.ts_start, 'ts_start')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Count Unique XY</Label>
                                    <LongDetailCell data={data.cnt_unique_xy ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.cnt_unique_xy, 'cnt_unique_xy')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Additional Info</Label>
                                    <LongDetailCell data={data.additional_info ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.additional_info, 'additional_info')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Extended Info</Label>
                                    <LongDetailCell data={data.extended_info ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.extended_info, 'extended_info')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Bio Durations Millis</Label>
                                    <LongDetailCell data={data.bio_duration_millis ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.bio_duration_millis, 'bio_duration_millis')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Auth Inference Canary</Label>
                                    <LongDetailCell data={data.auth_inference_canary ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.auth_inference_canary, 'auth_inference_canary')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Most Common XY</Label>
                                    <LongDetailCell data={data.most_common_xy ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.most_common_xy, 'most_common_xy')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Key Values</Label>
                                    <LongDetailCell data={replaceComma(JSON.stringify(data.key_values)).replaceAll(',[',', [') ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(replaceComma(JSON.stringify(data.key_values)).replaceAll(',[',', ['), 'key_values')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Rooted Inference</Label>
                                    <LongDetailCell data={data.rooted_inference ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.rooted_inference, 'rooted_inference')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Auth User Inference</Label>
                                    <LongDetailCell data={data.auth_user_inference ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.auth_user_inference, 'auth_user_inference')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> App Package Name</Label>
                                    <LongDetailCell data={data.app_package_name ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.app_package_name, 'app_package_name')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> App Version Code</Label>
                                    <LongDetailCell data={data.app_version_code_str ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.app_version_code_str, 'app_version_code_str')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> CID</Label>
                                    <LongDetailCell data={data.cid ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.cid, 'cid')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Component Device Count</Label>
                                    <LongDetailCell data={data.component_device_count ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.component_device_count, 'component_device_count')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Component User Count</Label>
                                    <LongDetailCell data={data.component_user_count ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.component_user_count, 'component_user_count')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Component Automation Count</Label>
                                    <LongDetailCell data={data.component_automation_count ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.component_automation_count, 'component_automation_count')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Component Inhuman Count</Label>
                                    <LongDetailCell data={data.component_inhuman_count ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.component_inhuman_count, 'component_inhuman_count')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Component Auth</Label>
                                    <LongDetailCell data={data.component_auth ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.component_auth, 'component_auth')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Timestamp Anomaly</Label>
                                    <LongDetailCell data={data.is_timestamp_anomaly ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.is_timestamp_anomaly, 'is_timestamp_anomaly')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device Type</Label>
                                    <LongDetailCell data={data.device_type ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device_type, 'device_type')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device Brand</Label>
                                    <LongDetailCell data={data.device_brand ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device_brand, 'device_brand')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device Manufacturer</Label>
                                    <LongDetailCell data={data.device_manufacturer ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device_manufacturer, 'device_manufacturer')}/>
                                </div>
                                <div>
                                    <Label><KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-edit svg-icon-2'
                                    /> Device Marketing Name</Label>
                                    <LongDetailCell data={data.device_marketing_name ?? 'N/A'}
                                                    routeOnClick={() => fetchFilteredData(data.device_marketing_name, 'device_marketing_name')}/>
                                </div>
                            </div>
                            <div className={'img_details_num'}>
                                <div>
                                    <Label>Auth Score</Label>
                                    <p>{data.auth_score ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Type</Label>
                                    <p>{data.type ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Motion Down</Label>
                                    <p>{data.cnt_motion_down ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Segments</Label>
                                    <p>{data.cnt_segments ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Sequence Acc</Label>
                                    <p>{data.cnt_seq_acc ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Sequence Motion</Label>
                                    <p>{data.cnt_seq_motion ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Emu Score</Label>
                                    <p>{data.emu_score ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Input Devices Inference</Label>
                                    <p>{data.input_devices_inference ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Labeled Auth Label</Label>
                                    <p>{data.labeled_auth_label ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Group</Label>
                                    <p>{data.group ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Max Group Similarity</Label>
                                    <p>{data.max_group_similarity ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Min Group Similarity</Label>
                                    <p>{data.min_group_similarity ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Seg In Session</Label>
                                    <p>{data.seg_count ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Index</Label>
                                    <p>{data.index ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>Emu Inference</Label>
                                    <p>{data.emu_inference ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Seg Duration Millis</Label>
                                    <p>{data.seg_duration_millis ?? 'N/A'}</p>
                                </div>
                                <div>
                                    <Label>#Motion Move</Label>
                                    <p>{data.cnt_motion_move ?? 'N/A'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default DetailsModal
